import React from 'react'
import Tag from './Tag'




const Taglist = ({taglist, className, size}) => {
  return (
    <div className={`taglist flex flex-wrap gap-y-0 mt-3 w-full mb-1 gap-1 text-lg text-center justify-start z-[1] ${size == "large" ? 'md:gap-x-2' : '' } ${className}`}>

    {taglist && taglist.map((_tag) => {
        
        let tag = _tag.fields ? _tag.fields : _tag

        return (
        <Tag data={tag} size={size}></Tag>
        )

    })}
    </div>
  )
}

export default Taglist