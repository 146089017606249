import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route, Switch, Link } from 'react-router-dom';

import { Element, ScrollLink, animateScroll } from 'react-scroll'

import { useEffect } from 'react';



import Navbar from './components/Navbar';

import LeftCard from './components/cards/LeftCard';
import RightCard from './components/cards/RightCard';
import Left60Card from './components/cards/Left60Card';
import Right40Card from './components/cards/Right40Card';
import Left40Card from './components/cards/Left40Card';
import Right60Card from './components/cards/Right60Card';
import FlexCardContainer from './components/FlexCardContainer';
import LeftFullCard from './components/cards/LeftFullCard';
import RightFullCard from './components/cards/RightFullCard';
import Projects from './components/Projects';
import IntroCard from './components/IntroCard';
import SkillsExperience from './components/SkillsExperience';
import Contact from './components/Contact';
import ProjectDetail from './components/ProjectDetail';
import HeadingCard from './components/HeadingCard';
import FeaturedProjects from './components/FeaturedProjects';
import Layout from './Layout';



const Home = () => {

  //check whether url is #Section, then auto scroll to that section using ScrollLink


  useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash.substring(1);
    console.log("hash " + hash)
    // If hash exists, scroll to the element with the corresponding ID
    if (hash) {
      /*animateScroll.scrollTo(`#${hash}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });*/
      let elem = document.getElementById((`#${hash}`))
                if (elem) {
                    elem.scrollIntoView({behavior: "smooth",block: "center"})
                    window.location.hash = "";
                    
      }
    }
  }, []);

  return (
    <>
    <Element id="#about"> </Element>
    <Element id="#home"> </Element>
      <Layout>
      
      <FlexCardContainer>
        <IntroCard></IntroCard>
        <SkillsExperience></SkillsExperience>
      </FlexCardContainer>
      
      <Element id="#projects">
      <div className='flex gap-2 md:gap-1 items-center'>
      <HeadingCard scale="large" title="Featured Projects"></HeadingCard>
      <Link className='h-fit' to="/projects">
      <HeadingCard isLink="true" title="View More"></HeadingCard>
      </Link>
      </div>
      <FeaturedProjects></FeaturedProjects>

      <Routes>
      <Route exact={true} path="/project/:slug" component={<ProjectDetail/>}></Route>
      </Routes>

      </Element>
      <Element id="#contact">
      <FlexCardContainer >

      
      <Contact></Contact>
      </FlexCardContainer>
      </Element>
      </Layout>
      </>
  )
}

export default Home