import React from 'react'

const FlexCardContainer = ({children}) => {
  return (
    <div className='flexcardcontainer flex md:flex-col xl:flex-col flex-row w-[100%] z-3'>
      {children}
    </div>
  )
}

export default FlexCardContainer;