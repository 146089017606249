import {useEffect, useState, React} from 'react'

import LeftCard from './cards/LeftCard';
import RightCard from './cards/RightCard';
import Left60Card from './cards/Left60Card';
import Right40Card from './cards/Right40Card';
import Left40Card from './cards/Left40Card';
import Right60Card from './cards/Right60Card';
import FlexCardContainer from './FlexCardContainer';
import LeftFullCard from './cards/LeftFullCard';
import RightFullCard from './cards/RightFullCard';
import Layout from '../Layout';
import Contact from './Contact';

import { Element } from 'react-scroll';

import ProjectCard from './ProjectCard';

import { createClient } from 'contentful';
import HeadingCard from './HeadingCard';
import CardRow from './CardRow';

const bothDefined = (pair) => {
  return (pair[0] && pair[1])

}


const Projects = () => {

  const client = createClient({space:"vjwrd4ix87z3", accessToken:"GxBBkVPuufbVQzv57Eosp_OZUhu0BoNxRpdOp7bKXmA"})

  const [ProjectsData, setProjectsData] = useState([]);

  const [WebProjects, setWebProjects] = useState([]);

  const [GameProjects, setGameProjects] = useState([]);

  const [AppProjects, setAppProjects] = useState([]);

  useEffect(() => {
    const getAllProjects = async () => {
      try {
        await client.getEntries({
          content_type : "project",
        }).then((entries) => {
          let webprojects = []
          let gameprojects = []
          let appprojects = []
          console.log(entries);
          webprojects = (entries.items.filter( (entry) => entry.fields.type === "Website").map((entry) => {
            console.log("website")
            return(entry.fields)
          }))
          console.log(webprojects)
          setWebProjects(webprojects);

          gameprojects = entries.items.filter( (entry) => entry.fields.type == "Game").map((entry) => {
            return(entry.fields)
          })

          setGameProjects(gameprojects);

          appprojects = entries.items.filter( (entry) => entry.fields.type == "App").map((entry) => {
            return(entry.fields)
          })

          setAppProjects(appprojects);

          
          //setProjectsData(projectsformatted)
        })


      } catch (error) {


      }

    }

    getAllProjects();

  },[])



  return (
    <>
      <Layout pagetitle={"Projects"}>
        <HeadingCard scale="large" title="Projects"></HeadingCard>

      {GameProjects.length > 0 && (<><HeadingCard title="Games"></HeadingCard>
      <CardRow>
      {GameProjects.map( (project) => (
        <ProjectCard project={project}></ProjectCard>

      )
      )}
     </CardRow></>)}

     {WebProjects.length > 0 && (<><HeadingCard title="Websites"></HeadingCard>
      <CardRow>
      {WebProjects.map( (project) => (
        <ProjectCard project={project}></ProjectCard>

      )
      )}
     </CardRow></>)}

     {AppProjects.length > 0 && (<><HeadingCard title="Apps"></HeadingCard>
      <CardRow>
      {AppProjects.map( (project) => (
        <ProjectCard project={project}></ProjectCard>

      )
      )}
     </CardRow></>)}
     

     <FlexCardContainer >

      
    <Contact></Contact>
    </FlexCardContainer>


      </Layout>
    
    
    
    
    
    
    </>
  )
}

export default Projects