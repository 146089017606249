import React from 'react'

import AboutData from '../assets/data/aboutme.json'
import Right40Card from './cards/Right40Card'
import Taglist from './Taglist'

const SkillsExperience = () => {
  return (
    <Right40Card>
        <h1 className='p-2 pb-0 pl-3 md:text-black'>What I can do</h1>
        <Taglist className='px-3' size="" taglist={AboutData.skills}>

        </Taglist>
        <h1 className='text-3xl p-2 pb-0 pl-3 md:text-black md:text-2xl'>What I have done</h1>
        {AboutData.experience && AboutData.experience.map((ele) => {
            return (
                <div className='mx-3 mt-2 mb-3'>
                    <h3 className='text-lighterblue leading-5 md:leading-4'>{ele.title}</h3>
                    <h3 className=' md:text-black leading-5 md:leading-4'>{ele.where}</h3>
                    <h3 className='text-gray-700 leading-5 md:leading-4'>{ele.dates}</h3>
                </div>
            )

        })}

    </Right40Card>
  )
}

export default SkillsExperience