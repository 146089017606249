import React from 'react'

import Card from './cards/Card';
import LeftCard from './cards/LeftCard';
import RightCard from './cards/RightCard';
import Left60Card from './cards/Left60Card';
import Right40Card from './cards/Right40Card';
import Left40Card from './cards/Left40Card';
import Right60Card from './cards/Right60Card';
import FlexCardContainer from './FlexCardContainer';
import LeftFullCard from './cards/LeftFullCard';
import RightFullCard from './cards/RightFullCard';

import useMediaQuery from './hooks/useMediaQuery';
import Taglist from './Taglist';
import { Link } from 'react-router-dom';

import { FaExternalLinkAlt } from "react-icons/fa";


const ProjectCard = ({children, project, isPair, index}) => {
    

    const isMobile = useMediaQuery('(max-width: 767px)')

    console.log(project);

    const returnInfo = (project, leftright) => {
        return (
            <div className='relative flex h-full w-full rounded-lg'>
            <Link to={`/projects/${project.slug}`} className='h-full md:w-full w-full absolute z-[10]  top-0'></Link>
            <div className='p-3 rounded-lg w-full h-full bg-gradient-to-b z-[5] from-[#011131] to-[#4242426e] flex flex-col'>
                <div className='flex gap-4 items-center'>
                <h1 className="w-fit text-balance md:text-xl text-white ">{project.title}
                <h3 className=' z-[13] relative text-gray-300'>{`${project.year} · ${project.type} `}{project.authors ? project.authors.map((author) => (<span className='text-lg md:text-sm'> · {author}</span>)) : ''}</h3>
                </h1>
                
                </div>
                <p className=' self-start h-fit text-ellipsis text-blue-200'>{project.description}</p>
                <Taglist className='' taglist={project.skills}></Taglist>
            </div>
           
            <div className='absolute w-full h-full z-[3]'>

                {project.images && <img className = {` w-full h-full rounded-lg object-cover backdrop:blur-sm ` } src={project.images[0].fields.file.url} alt={`${project?.title}  ${project.images[0]?.fields?.title}`}></img>}
                
            </div>
            

            
            </div>

        )
    }



    const renderCard = (project, isPair, index) => {
        /*if (index == 0) {
            if (project.importance == 1) {
                if (isPair) {
                    return <Left40Card isLink={true}>{returnInfo(project, index === 0)}</Left40Card>
                }
            } else if (project.importance == 3) {
                return <Left60Card isLink={true}>{returnInfo(project, index === 0)}</Left60Card>
            }
            return <LeftCard isLink={true}>{returnInfo(project, index === 0)}</LeftCard>
        } else {
            if (project.importance == 1) {
                if (isPair) {
                    return <Right40Card isLink={true}>{returnInfo(project,index === 0)}</Right40Card>
                }
            } else if (project.importance == 3) {
                return <Right60Card isLink={true}>{returnInfo(project, index === 0)}</Right60Card>
            }
            return <RightCard isLink={true}>{returnInfo(project, index === 0)}</RightCard>
        }*/
        return <div className='p-3'><Card className='' isLink={true} fit={true}>{returnInfo(project, index === 0)}</Card></div>



    }



  return (
    <>
    {renderCard(project, isPair, index)}
    </>    
  )

}


export default ProjectCard