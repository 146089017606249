import React from 'react'

import AboutData from '../assets/data/aboutme.json'
import Left60Card from './cards/Left60Card'

import { FaExternalLinkAlt } from "react-icons/fa";

import { Link } from 'react-router-dom';
import Taglist from './Taglist';


const IntroCard = () => {

  const IntroCardRender = () => {
    return (
      <div className='flex flex-col px-5 gap-0 h-full pb-3'>
        {AboutData.introtitle && <h1 className='text-4xl my-0 p-0 leading-[4rem] md:leading-[4rem] text-black'>{AboutData.introtitle}</h1> }
        {AboutData.introjobtitle && <h2 className='my-0 p-0 text-lighterblue'>{AboutData.introjobtitle}<span></span>
        <span className='pl-1 text-black'> currently {AboutData.currentlywhat}</span> 
        {AboutData.currentlyStatus && <Link className=' text-black hover:text-lighterblue transition ease-in-out duration-600 underline inline-block' to={AboutData.IMLink} target='_blank'>{AboutData.currentlyStatus}
        <FaExternalLinkAlt className='float-right px-2 md:my-auto md:h-fit text-4xl md:text-3xl md:hidden  '></FaExternalLinkAlt></Link> } </h2> }
        {AboutData.intromessage && <h3 className='my-2 text-gray-700'>{AboutData.intromessage} </h3>}
        <Taglist className="mt-auto gap-5 md:gap-1" size="" taglist={AboutData.links}></Taglist>
      </div>

    );

  }
  return (
    <Left60Card>
      {IntroCardRender()}

    </Left60Card>
  )
}

export default IntroCard;