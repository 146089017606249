import React, { useEffect } from 'react'
import Card from './Card';



const LeftCard = ({children, isLink}) => {
  
  return (
    <Card className ='bg-gradient-secondary min-h-[10vh]  mr-3 rounded-xl w-[50%] xl:w-[95%] my-3' isLink={isLink}  rightleft="left">
        {children}
    </Card>
  )
}

export default LeftCard;