import React from 'react'

import Card from './Card';

const Right40Card = ({children, isLink}) => {
  return (
    <Card className='min-h-[40vh]  ml-3 rounded-l-xl w-[40%] xl:w-[95%] my-3' rightleft="right" isLink={isLink} >{children}</Card>
  )
}

export default Right40Card