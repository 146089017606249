import {useState, React, useEffect} from 'react'

import logo from '../assets/logo.png'

import { FaBars, FaHamburger, FaTimes } from "react-icons/fa"
import useMediaQuery from './hooks/useMediaQuery';

import { Link } from 'react-router-dom';

import AboutData from '../assets/data/aboutme.json'

import downloadDocument from './downloadPDF';

import { Link as ScrollLink } from 'react-scroll';



const Navbar = ({children, page}) => {

    const [Visible,setVisible] = useState(false);

    const handleClick = () => {
        setVisible(!Visible);
    }

    const [MoreVisible, setMoreVisible] = useState(false);

    const handleMoreClick = () => {
        setMoreVisible(!MoreVisible);

    }
    


    const isMobile = useMediaQuery('(max-width: 767px)')

    //const leftLinks =  ["Home", "Projects", "Contact", "About"];


  return (
    <div className="bg-gradient-secondary min-h-[5vh] h-fit rounded-b-3xl top-0 right-0 left-0 w-[96%] m-auto fixed z-[1000]">
        <div className=" md:flex flex md:justify-around md:w-fit md:m-auto">
        <Link className=' w-fit hover:scale-105 transition duration-300 ease-in-out' to="/">
        <img src={logo} className='object-contain h-[7vh] py-2 px-5'></img>
        </Link>
        <div className='md:block hidden my-auto' onClick={handleClick}>
            {Visible ? (<FaTimes size={30} ></FaTimes>) :  (<FaBars size={30} ></FaBars>)  }
        </div>
        <div className="md:hidden flex flex-row justify-between w-full pr-4">
        <ul className='md:hidden flex list-none flex flex-row'>
         {AboutData.navlinks && AboutData.navlinks.map( (ele) => {
                return (
                    <>
                    { ele.type === "scroll" ? (
                          (page == "Home" ? (
                            <ScrollLink className=' my-auto m-auto p-2 text-2xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={ele.link} spy={true} smooth={true} duration={500}>
                    <li>
                        {ele.name}
                    </li>
                    </ScrollLink>) : (
                        <Link className=' my-auto m-auto p-2 text-2xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={`/${ele.link}`}>
                        
                        <li>
                            {ele.name}
                        </li>
                       
                    </Link>))) : 
                    (<Link className=' my-auto m-auto p-2 text-2xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={ele.link}>
                    <li>
                        {ele.name}
                    </li>
                    </Link>)
                }
                </>

                )
            })}
 
        </ul>
        <ul className='list-none flex flex-row'>
         {AboutData.links && AboutData.links.map( (ele) => {
                return (
                    <>{ele.link && <li className='lg:hidden my-auto m-auto p-2 text-xl hover:scale-110 transition ease-in-out duration-400 hover:underline'>
                        <Link onClick = {ele.link == "/CV Joseph Hutchinson.pdf"  ? downloadDocument : null} to={ele.link == "/CV Joseph Hutchinson.pdf" ? ''  : ele.link}>
                    {ele.name}</Link></li>}</>
         

                )
            }
            
         )}
            <li className='lg:inline-block hidden  my-auto m-auto p-2 text-2xl hover:scale-110 transition ease-in-out duration-400 hover:underline ' onClick={handleMoreClick}> { MoreVisible ? (<FaTimes size={30} ></FaTimes>) :  (<FaBars size={30} ></FaBars>)  }</li>
        </ul>
        </div>
        </div>
        

        
        <div className={` w-[80%] m-auto ${(MoreVisible && !isMobile) ? "lg:block hidden" : "hidden"}`}>
        <ul className=' flex list-none flex flex-col'>
        {AboutData.links && AboutData.links.map( (ele) => {
                return (
                    <li className=' s my-auto m-auto p-2 text-3xl hover:scale-110 transition ease-in-out duration-400 hover:underline'>
                        <Link onClick = {ele.link == "/CV Joseph Hutchinson.pdf"  ? downloadDocument : null} to={ele.link == "/CV Joseph Hutchinson.pdf" ? ele.link : `${ele.link}`} target={ele.link == "/CV Joseph Hutchinson.pdf" ? '_blank' : ''}>{ele.name}</Link>
                    </li>

                )
            })}
            </ul>
            </div>


        <div className={` w-[80%] m-auto ${Visible && isMobile ? "block" : "hidden"}`}>
        <ul className=' flex list-none flex flex-col'>
         {AboutData.navlinks && AboutData.navlinks.map( (ele) => {
                return (
                    <>
                        {ele.type === "scroll" ? 
                            (page !== "Home" ? (
                                <ScrollLink className='my-auto m-auto p-2 md:text-xl text-3xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={ele.link} spy={true} smooth={true} duration={500} onClick={handleClick}>
                                    <li>
                                        {ele.name}
                                    </li>
                                </ScrollLink>
                            ) : (
                                <Link className='my-auto m-auto p-2 md:text-xl text-3xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={"/"}>
                                    <li>
                                        {ele.name}AHH
                                    </li>
                                </Link>
                            ))
                            : (
                                <Link className='my-auto m-auto p-2 md:text-xl text-3xl hover:scale-110 transition ease-in-out duration-400 hover:underline' to={ele.link}>
                                    <li>
                                        {ele.name}
                                    </li>
                                </Link>
                            )}
                    </>
                )
                
            })}
         {AboutData.links && AboutData.links.map( (ele) => {
                return (
                    <li className=' s my-auto m-auto p-2 md:text-xl text-3xl hover:scale-110 transition ease-in-out duration-400 hover:underline'>
                        <Link onClick = {ele.link == "/CV Joseph Hutchinson.pdf"  ? downloadDocument : null} to={ele.link == "/CV Joseph Hutchinson.pdf" ? ele.link : `${ele.link}`} target={ele.link == "/CV Joseph Hutchinson.pdf" ? '_blank' : ''}>{ele.name}</Link>
                    </li>

                )
            })}
            


        </ul>


        </div>
    </div>
    
  )
}

export default Navbar