import React, { useEffect } from 'react'

import {motion, useAnimation} from 'framer-motion'
import { useInView } from 'react-intersection-observer';

import useMediaQuery from '../hooks/useMediaQuery';

const Card = ({children, className, rightleft, isLink, fit}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    
    const isMobile = useMediaQuery('(max-width: 767px)')


    const cardVariants = {
      hidden: { opacity: 0, x: rightleft == "left" ? -700 : 700},
      visible: { opacity: 1, x: 0, transition: {duration: 1} },
    }
  
    useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden")
    }
    } , [controls, inView]);
  
  
    return (

        <div className={` h-full card  ${fit ? 'md:w-fit' : 'md:w-[90%]'} ${isLink ? ' hover:scale-105  w-full ease-in-out duration-[400ms] bg-gradient-primary text-white' : 'bg-gradient-secondary'} framer rounded-lg  ${className}`}>{children}</div>
   
      

    

    )
  }

export default Card