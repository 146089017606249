import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'

import { FaExternalLinkAlt, FaExpand } from "react-icons/fa";

import Navbar from './Navbar'
import Left60Card from './cards/Left60Card'
import Taglist from './Taglist'
import Right40Card from './cards/Right40Card'
import FlexCardContainer from './FlexCardContainer'
import YoutubeEmbed from './YoutubeEmbed'
import Card from './cards/Card';

import {Modal, Button} from 'react-bootstrap'

import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {CreateClient, createClient} from 'contentful'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';


const ProjectDetail = (props) => {

    const client = createClient({space:"vjwrd4ix87z3", accessToken:"GxBBkVPuufbVQzv57Eosp_OZUhu0BoNxRpdOp7bKXmA"})

    const {slug} = useParams()

    const [project, setProject] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
      if (project?.embedId) {
        image += 1;
      }
      setSelectedImage(image)
      setShowModal(true)

    }

    const handleCloseModal = () => {
      setShowModal(false)
    }


    useEffect(() => {
      const findProjectBySlug = async () => {
        try {
          await client.getEntries({
          }).then((entries) => {
            entries.items.map( (entry) => {
              if (entry.fields.slug == slug) {
                setProject(entry.fields);
              }
            })
            //setProject(entries.fields)
          })
  
  
        } catch (error) {
  
  
        }
  
      }
  
      findProjectBySlug();
  
    },[slug])


  return (
    

    <div>
      
        <div className=' parent w-[95vw] m-auto'>
      <div className='bg-backgroundPattern h-screen bg-cover w-screen fixed z-[-100] left-0 top-0 pt-[8vh]'>
      </div>

      <Navbar currentpage={"Projects"}></Navbar>
      <div className='h-[7vh] '></div>
      <FlexCardContainer>
        <Left60Card>
          <div className=' text-black p-3 flex flex-col px-5 gap-0 h-full pb-3'>
          <h1 className='  sm:text-2xl md:text-2xl text-3xl align-middle'>{project?.title}{project?.itchlink && <><span> · </span> <Link className='hover:scale-110 z-[20]relative transition  ease-in-out duration-400 hover:underline inline-block md:text-lighestblue text-lighterblue text-2xl text-center' to={project?.itchlink} target="_blank">Itch.io <FaExternalLinkAlt  className='float-right px-2 md:my-auto md:h-fit text-4xl md:text-3xl md:my-1' /></Link></>}</h1> 
          <h2 className='md:text-lg text-2xl'>{project?.description}</h2>
          <Taglist className="gap-3" size="large" taglist={project?.skills}></Taglist>
          </div>
        </Left60Card>
        <Right40Card>
          <div className='relative'>
        <Carousel fade slide className='p-2'>
            {project.embedId && 
              <Carousel.Item key='YoutubeEmbed' className=' carouselitem'>
                <YoutubeEmbed embedId={project.embedId} className=""/>

              </Carousel.Item>
            }
            {project?.images?.map( (image, index) => (
              
              <Carousel.Item onClick={() => handleImageClick(index)} className='hover:outline-black' key={index}>
                <img className='w-[100%] hover:scale-105' src={image?.fields?.file?.url} alt ={`${project?.title}  ${image?.fields?.title}`} ></img>
                <div className='absolute w-full h-full top-0 flex hover:visible opacity-0 hover:opacity-100 transition ease-in-out duration-300 z-[] '>
                            <FaExpand className =' mt-auto m-auto align-middle text-6xl text-white z-10' />
                          </div>
                          
              </Carousel.Item>

            ))}
            </Carousel>
           {/*} <div class="carousel">
            {project.embedId && 
              <div class="carousel-item" key='YoutubeEmbed'>
                <YoutubeEmbed embedId={project.embedId} />

              </div>
            }
            {project?.images?.map( (image, index) => (
              
              <div class="carousel-item" onClick={() => handleImageClick(image)} className='hover:outline-black' key={index}>
                <img className='w-[100%] hover:scale-105' src={image?.fields?.file?.url} alt ={`${project?.title}  ${image?.fields?.title}`} ></img>
                <div className='absolute w-full h-full top-0 flex hover:visible opacity-0 hover:opacity-100 transition ease-in-out duration-300 z-[] '>
                            <FaExpand className =' mt-auto m-auto align-middle text-6xl text-white z-10' />
                          </div>
                          
              </div>
            ))}

            </div>
            </div>*/}
          </div>
        </Right40Card>
        </FlexCardContainer>
        {project.article &&
        <Card className='p-4 w-[75%] m-auto'>
          <div>
            <>{documentToReactComponents(project?.article)}</>
          </div>
        </Card>}

        <Modal show={showModal} onHide={handleCloseModal} size='xl' dialogClassName="modal-90w" className='w-full' centered>
        <Modal.Body className='bg-gradient-secondary p-0 rounded-lg'>
        {/*<img
            className="img-fluid"
            src={selectedImage?.fields?.file?.url}
            alt="Full Screen Image"
        />*/}
          <Carousel fade slide className='p-2' defaultActiveIndex={selectedImage}>
            {project.embedId && 
              <Carousel.Item key='YoutubeEmbed' >
                <YoutubeEmbed className='' embedId={project.embedId} />

              </Carousel.Item>
            }
            {project?.images?.map( (image, index) => (
              
              <Carousel.Item key={index}>
                <img className='h-full object-conver' src={image?.fields?.file?.url} alt ={`${project?.title}  ${image?.fields?.title}`} ></img>
                
              </Carousel.Item>

            ))}
            </Carousel>
        </Modal.Body>

      </Modal>
        


      </div>
    </div>
  )
}

export default ProjectDetail