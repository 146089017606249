import {useEffect, useState, React} from 'react'

import LeftCard from './cards/LeftCard';
import RightCard from './cards/RightCard';
import Left60Card from './cards/Left60Card';
import Right40Card from './cards/Right40Card';
import Left40Card from './cards/Left40Card';
import Right60Card from './cards/Right60Card';
import FlexCardContainer from './FlexCardContainer';
import LeftFullCard from './cards/LeftFullCard';
import RightFullCard from './cards/RightFullCard';


import ProjectCard from './ProjectCard';

import { createClient } from 'contentful';
import CardRow from './CardRow';



const FeaturedProjects = () => {

  const client = createClient({space:"vjwrd4ix87z3", accessToken:"GxBBkVPuufbVQzv57Eosp_OZUhu0BoNxRpdOp7bKXmA"})


  const [ProjectsData, setProjectsData] = useState([]);

  useEffect(() => {
    const getAllProjects = async () => {
      try {
        await client.getEntries({
          content_type : "project",
        }).then((entries) => {
          const projectsformatted = entries.items.filter((entry) => entry.fields.featured).map((entry) => {
           

            return (entry.fields)
            
          })
          console.log(projectsformatted)
          setProjectsData(projectsformatted)
        })


      } catch (error) {


      }

    }

    getAllProjects();

  },[])

  useEffect(() => {


  }, [ProjectsData]);


  return (
    <>

    <CardRow>
      {ProjectsData.map( (project) => (
        <ProjectCard project={project}></ProjectCard>

      )
      )}
     </CardRow>
    
    
    
    
    
    
    </>
  )
}

export default FeaturedProjects