import React from 'react'

import { useRef, useState, useEffect } from 'react';

const YoutubeEmbed = ({embedId, className}) => {

  const containerRef = useRef(null);
  const [aspectRatioHeight, setAspectRatioHeight] = useState(0);

  useEffect(() => {
    const calculateAspectRatioHeight = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        
        const height = (containerWidth / 16) * 9; // 16:9 aspect ratio
        setAspectRatioHeight(height);
        console.log(containerWidth + " " + height)
      }
    };

    calculateAspectRatioHeight();

    // Recalculate height when window is resized
    const handleResize = () => {
      calculateAspectRatioHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div ref={containerRef} className={ `relative w-full h-full overflow-hidden ${className}`} style={{height : aspectRatioHeight}}>
      <iframe
        height = {`${aspectRatioHeight}px`}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        className={`w-full h-[400px] rounded-2xl`}
      />
    </div>
  )}

export default YoutubeEmbed