import React from 'react'

import Card from './cards/Card'

const HeadingCard = ({title, isLink, scale}) => {
  return (
    <Card fit={true} isLink={isLink} className={`text-center w-fit m-2 p-3 md:p-1 md:m-0`}>
        {scale === 'large' ? <h1>{title}</h1> : <h2>{title}</h2>}
      </Card>
  )
}

export default HeadingCard