import React from 'react'

import Navbar from './components/Navbar';

import LeftCard from './components/cards/LeftCard';
import RightCard from './components/cards/RightCard';
import Left60Card from './components/cards/Left60Card';
import Right40Card from './components/cards/Right40Card';
import Left40Card from './components/cards/Left40Card';
import Right60Card from './components/cards/Right60Card';
import FlexCardContainer from './components/FlexCardContainer';
import LeftFullCard from './components/cards/LeftFullCard';
import RightFullCard from './components/cards/RightFullCard';
import Projects from './components/Projects';
import IntroCard from './components/IntroCard';
import SkillsExperience from './components/SkillsExperience';
import Contact from './components/Contact';
import ProjectDetail from './components/ProjectDetail';
import HeadingCard from './components/HeadingCard';
import FeaturedProjects from './components/FeaturedProjects';


const Layout = ({pagetitle, children}) => {
  return (
    <div className=' parent w-[95vw] m-auto'>
      <div className='bg-backgroundPattern h-screen bg-cover w-screen fixed z-[-100] left-0 top-0 pt-[8vh]'>
      </div>

      <Navbar page={pagetitle}></Navbar>
      <div className='h-[7vh]'></div>
      {children}
    </div>
  )
}

export default Layout