import React from 'react'

import AboutData from '../assets/data/aboutme.json'
import LeftFullCard from './cards/LeftFullCard'
import Left40Card from './cards/Left40Card'
import LeftCard from './cards/LeftCard'

import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Link } from 'react-router-dom'
import { FaLinkedin } from "react-icons/fa";

const Contact = () => {
  return (
    <LeftCard>
        <div className='p-3 w-full h-full text-black'>
        <h2 className='md:text-2xl mb-2'>Contact</h2>
        <div className='inline-block w-full p-2'>
            <BsFillTelephoneFill className='float-left mx-3  md:text-2xl'></BsFillTelephoneFill>
            <h2 className=''>{AboutData.telephone}</h2>
        </div>
        <div className='inline-block w-full p-2'>
            <MdEmail  className='float-left mx-3 text-4xl md:text-2xl'></MdEmail>
            <h2 className=''>{AboutData.email}</h2>
        </div>
        <div className='inline-block w-full p-2'>
            <Link to="https://www.linkedin.com/in/joe-hutchinson-393367275/" target="_blank" className='hover:text-lighterblue'><h2></h2>
            <FaLinkedin  className='float-left mx-3 text-4xl md:text-2xl'></FaLinkedin>
            <h2 className=''>LinkedIn</h2>
            </Link>
        </div>
        


</div>
    </LeftCard>
  )
}





export default Contact