import React from 'react'

import { Link } from 'react-router-dom'

import downloadDocument from './downloadPDF';

const Tag = ({data, size}) => {
  return (
    data.link && (
      <Link target="_blank" onClick={data.link == '/CV Joseph Hutchinson.pdf' ? downloadDocument : null} className={`p-2 md:p-1 rounded-md bg-gradient-primary text-white hover:text-blue-100 hover:scale-110 transition ease-in-out duration-600  text-gray-800 drop-shadow-lg   my-1`} to={data.link}>
        {size === 'large' ? <h2>{data.name}</h2> : <p>{data.name}</p>}
      </Link>
    )
    || (
      <div className={`p-2 md:p-1 rounded-md bg-gradient-secondary-flipped text-gray-800 drop-shadow-lg md:text-xs my-1 ${size == "large" ? '' : ''}`}>
        {size === 'large' ? <h2>{data.name}</h2> : <p>{data.name}</p>}
      </div>
    )
  );
    
}

export default Tag