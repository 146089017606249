import React from 'react'

import Layout from '../Layout'
import HeadingCard from './HeadingCard'

import { Link } from 'react-router-dom'

import downloadDocument from './downloadPDF';

const CV = () => {
  return (
    <Layout pagetitle={"Projects"}>
        <div className='flex gap-2'>
    <HeadingCard title="CV"></HeadingCard>
    <Link className='h-fit' to='' onClick={downloadDocument} target="/JosephHutchinsonCV.pdf">
    <HeadingCard isLink={true} title="Download"></HeadingCard>
    </Link>
    </div>
    <iframe src="/JosephHutchinsonCV.pdf" width="80%" className=' my-2 m-auto h-[100vh]' />

    </Layout>
  )
}

export default CV