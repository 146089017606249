import React from 'react'

import Card from './Card';

const Left60Card = ({children, isLink}) => {
  return (
    <Card className='min-h-[40vh] mr-3 rounded-r-xl w-[60%] xl:w-[95%] my-3'  rightleft="left" isLink={isLink} >{children}</Card>
  )
}

export default Left60Card