import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route, Switch, HashRouter } from 'react-router-dom';

import { Element } from 'react-scroll'

import Navbar from './components/Navbar';

import LeftCard from './components/cards/LeftCard';
import RightCard from './components/cards/RightCard';
import Left60Card from './components/cards/Left60Card';
import Right40Card from './components/cards/Right40Card';
import Left40Card from './components/cards/Left40Card';
import Right60Card from './components/cards/Right60Card';
import FlexCardContainer from './components/FlexCardContainer';
import LeftFullCard from './components/cards/LeftFullCard';
import RightFullCard from './components/cards/RightFullCard';
import Projects from './components/Projects';
import IntroCard from './components/IntroCard';
import SkillsExperience from './components/SkillsExperience';
import Contact from './components/Contact';
import ProjectDetail from './components/ProjectDetail';
import Home from './Home';
import CV from './components/CV';
import About from './components/About';

function App() {
  return (
    <HashRouter>
    

      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/projects" element={<Projects/>}></Route>
        <Route path="/cv" element={<CV/>}></Route>
        <Route path="/about" element={<About/> }></Route>
      <Route exact={true} path="/projects/:slug" element={<ProjectDetail/>}></Route>
      </Routes>

     
      
    </HashRouter>
  );
}

export default App;
