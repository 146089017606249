const downloadDocument = (e) => {

    e.preventDefault()

    const documentUrl = '/JosephHutchinsonCV.pdf';

    const link = document.createElement('a');
    link.href = documentUrl;
  
    // Specify the default file name for the download
    link.download = 'JosephHutchinsonCV.pdf';
  
    // Append the link to the document
    document.body.appendChild(link);
  
    // Trigger the click event to start the download
    link.click();
  
    // Remove the link from the document after the download
    document.body.removeChild(link);
  };
  
  export default downloadDocument;

