import React from 'react'

const CardRow = ({children}) => {
  return (
    <div className='cardrow grid grid-rows-1 w-full'>
      {children}
    </div>
  )
}

export default CardRow