import React from 'react'
import Layout from '../Layout'
import HeadingCard from './HeadingCard'
import IntroCard from './IntroCard'

const About = () => {
  return (
    <Layout>
        <HeadingCard title={"About me"} scale={"large"}></HeadingCard>
        <IntroCard></IntroCard>

    </Layout>

  )
}

export default About